import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    getImageUserRating: {
      metadata: {
        openApiTags: ['Getters'],
        openApiSecurity: [{ BearerAuth: [] }],
      },
      summary: '/ get-image-user-rating',
      description: "Gets the currently authenticated user's rating for the image with the specified imageId",
      method: 'GET',
      path: '/get-image-user-rating/:imageId',
      pathParams: z.object({
        imageId: z.string().uuid(),
      }),
      headers: z.object({
        authorization: z.string(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z
              .object({
                id: z.string().uuid(),
                image_id: z.string().uuid(),
                user_id: z.string().uuid(),
                rating: z.number(),
                max_rating: z.number(),
                updated_at: z.string().datetime(),
                created_at: z.string().datetime(),
              })
              .nullable(),
          })
          .openapi({
            title: 'Get Image User Rating',
            description: 'Get the rating of a specific user on an image',
          }),
        400: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        401: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        404: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
