import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    isCouponCodeValid: {
      metadata: {
        openApiTags: ['Actions'],
      },
      summary: '/ is-coupon-code-valid',
      description: 'Verifies the validity of a coupon code.',
      method: 'POST',
      path: '/is-coupon-code-valid',
      headers: z.object({
        authorization: z.string(),
      }),
      body: z.object({
        couponCode: z.string(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.object({
              stripe_promo_id: z.string().nullable(),
              stripe_coupon_id: z.string(),
              name: z.string().nullable(),
              promotion_code: z.string().nullable(),
              is_active: z.boolean(),
              is_public: z.boolean(),
              amount_off: z.number().nullable(),
              percent_off: z.number().nullable(),
              currency: z.string().nullable(),
              duration: z.enum(['forever', 'once', 'repeating']),
              duration_in_months: z.number().nullable(),
              max_redemptions: z.number().nullable(),
              expires_at: z.string().datetime().nullable(),
              times_redeemed: z.number(),
              first_time_transaction_only: z.boolean(),
              minimum_purchase_price: z.number().nullable(),
              minimum_purchase_price_currency: z.string().nullable(),
              updated_at: z.string().datetime(),
              created_at: z.string().datetime(),
            }),
          })
          .openapi({
            title: 'Is Coupon Code Valid',
            description: 'Verifies the validity of a coupon code.',
          }),
        400: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        404: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
