import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'
import { EnumImageGenerationStatus } from '../../../../../lib/database/lib/generated/enums'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    getCurrentUser: {
      metadata: {
        openApiTags: ['Getters'],
        openApiSecurity: [{ BearerAuth: [] }],
      },
      summary: '/ get-current-user',
      description: 'Gets the currently authenticated user',
      method: 'GET',
      path: '/get-current-user',
      headers: z.object({
        authorization: z.string(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.object({
              id: z.string().uuid(),
              email: z.string().email().nullable(),
              is_email_verified: z.boolean(),
              stripe_customer_id: z.string().nullable(),
              first_name: z.string().nullable(),
              middle_name: z.string().nullable(),
              last_name: z.string().nullable(),
              gender: z.string().nullable(),
              phone_number: z.string().nullable(),
              phone_number_is_verified: z.boolean().nullable(),
              picture_url: z.string().nullable(),
              is_deleted: z.boolean(),
              is_admin: z.boolean(),
              api_key: z.string().length(64).nullable(),
              updated_at: z.string().datetime(),
              created_at: z.string().datetime(),
              last_generate_id: z.string().uuid().nullable(),
              user_credits: z
                .object({
                  id: z.string().uuid(),
                  user_id: z.string().uuid(),
                  membership_credits: z.number(),
                  purchase_credits: z.number(),
                  updated_at: z.string().datetime(),
                  created_at: z.string().datetime(),
                })
                .nullable(),
              user_membership: z
                .object({
                  id: z.string().uuid(),
                  user_id: z.string().uuid(),
                  stripe_subscription_id: z.string().nullable(),
                  stripe_subscription_created_at: z.string().datetime().nullable(),
                  stripe_subscription_status: z.string().nullable(),
                  stripe_last_payment_error: z.string().nullable(),
                  membership_period: z.string(),
                  membership_type: z.string(),
                  num_monthly_credits: z.number(),
                  canceled_at: z.string().datetime().nullable(),
                  start_at: z.string().datetime(),
                  end_at: z.string().datetime(),
                  updated_at: z.string().datetime(),
                  created_at: z.string().datetime(),
                })
                .nullable(),
              user_image_generations: z
                .array(
                  z.object({
                    id: z.string().uuid(),
                    user_id: z.string().uuid(),
                    ip_address: z.string().nullable(),
                    webhook_url: z.string().nullable(),
                    gpu_provider_id: z.string(),
                    is_generated_through_api: z.boolean(),
                    status: z.nativeEnum(EnumImageGenerationStatus),
                    error_message: z.string().nullable(),
                    updated_at: z.string().datetime(),
                    created_at: z.string().datetime(),
                  }),
                )
                .nullable(),
              user_transactions: z
                .array(
                  z.object({
                    id: z.string().uuid(),
                    user_id: z.string().uuid(),
                    stripe_customer_id: z.string(),
                    stripe_subscription_id: z.string(),
                    stripe_product_id: z.string(),
                    stripe_price_id: z.string(),
                    stripe_coupon_id: z.string().nullable(),
                    stripe_promo_id: z.string().nullable(),
                    stripe_promotion_code: z.string().nullable(),
                    stripe_coupon_name: z.string().nullable(),
                    membership_type: z.string().nullable(),
                    membership_period: z.string().nullable(),
                    purchase_type: z.string(),
                    num_monthly_credits: z.number().nullable(),
                    selling_price: z.number().nullable(),
                    net_price: z.number().nullable(),
                    transaction_fee: z.number().nullable(),
                    tax_amount: z.number().nullable(),
                    discount_amount: z.number().nullable(),
                    currency: z.string().nullable(),
                    updated_at: z.string().datetime(),
                    created_at: z.string().datetime(),
                    user_transaction_invoices: z
                      .array(
                        z.object({
                          id: z.string().uuid(),
                          user_transaction_id: z.string().uuid(),
                          stripe_invoice_id: z.string(),
                          stripe_invoice_number: z.string(),
                          stripe_hosted_invoice_url: z.string(),
                          stripe_invoice_pdf: z.string(),
                          updated_at: z.string().datetime(),
                          created_at: z.string().datetime(),
                          user_transaction_invoice_fees: z
                            .array(
                              z.object({
                                id: z.string().uuid(),
                                user_transaction_invoice_id: z.string().uuid(),
                                amount: z.number(),
                                currency: z.string(),
                                description: z.string(),
                                stripe_type: z.string(),
                                updated_at: z.string().datetime(),
                                created_at: z.string().datetime(),
                              }),
                            )
                            .nullable(),
                        }),
                      )
                      .nullable(),
                  }),
                )
                .nullable(),
            }),
          })
          .openapi({
            title: 'Get Current User',
            description: 'Get the current authenticated user',
          }),
        401: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
