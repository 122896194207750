import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'
import { EnumImageGenerationStatus } from '../../../../../lib/database/lib/generated/enums'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    cancelGenerateImage: {
      metadata: {
        openApiTags: ['Actions'],
      },
      summary: '/ cancel-generate-image',
      description: 'Cancels an image that is currently being generated.',
      method: 'POST',
      path: '/cancel-generate-image',
      body: z.object({
        imageGenerationId: z.string().optional(),
        gpuProviderId: z.string().optional(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.object({
              status: z.nativeEnum(EnumImageGenerationStatus),
            }),
          })
          .openapi({
            title: 'Generate Image',
            description: 'Generates an image',
          }),
        400: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        403: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
