import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    enhancePrompt: {
      metadata: {
        openApiTags: ['Actions'],
      },
      summary: '/ enhance-prompt',
      description: 'Enhances a prompt.',
      method: 'POST',
      path: '/enhance-prompt',
      body: z.object({
        prompt: z.string(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.object({
              gpuProviderId: z.string(),
              originalPrompt: z.string(),
              enhancedPrompt: z.string(),
              delayTime: z.number(),
              executionTime: z.number(),
            }),
          })
          .openapi({
            title: 'Enhance Prompt',
            description: 'Enhances a prompt',
          }),
        400: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
