import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'
import {
  EnumImageGenerationStatus,
  EnumSize,
  EnumSizeType,
  EnumStyle,
  EnumStyleType,
} from '../../../../../lib/database/lib/generated/enums'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    getImageGenerationStatus: {
      metadata: {
        openApiTags: ['Getters'],
      },
      summary: '/ get-image-generation-status',
      description: 'Gets the image with the specified image ID.',
      method: 'GET',
      path: '/get-image-generation-status/:imageGenerationId',
      pathParams: z.object({
        imageGenerationId: z.string().uuid(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.object({
              id: z.string().uuid(),
              user_id: z.string().uuid(),
              gpu_provider_id: z.string(),
              is_generated_through_api: z.boolean(),
              status: z.nativeEnum(EnumImageGenerationStatus),
              error_message: z.string().optional(),
              images: z
                .array(
                  z.object({
                    id: z.string().uuid(),
                    aspect_ratio: z.string(),
                    generate_id: z.string().uuid(),
                    caption: z.string(),
                    detailed_caption: z.string(),
                    more_detailed_caption: z.string(),
                    object_detection: z.record(
                      z.string(),
                      z.array(z.object({ x1: z.number(), x2: z.number(), y1: z.number(), y2: z.number() })),
                    ),
                    prompt_grounded_object_detection: z.record(
                      z.string(),
                      z.array(z.object({ x1: z.number(), x2: z.number(), y1: z.number(), y2: z.number() })),
                    ),
                    height: z.number(),
                    is_generated_through_api: z.boolean(),
                    is_image_guided: z.boolean(),
                    is_private: z.boolean(),
                    is_hidden: z.boolean(),
                    nsfw_detected: z.boolean(),
                    nsfw_text_detected: z.boolean(),
                    png_s3_key: z.string(),
                    png_thumb_s3_key: z.string(),
                    jpg_s3_key: z.string(),
                    webp_s3_key: z.string(),
                    svg_s3_key: z.string(),
                    seed: z.string(),
                    orientation_type: z.string(),
                    size_type: z.nativeEnum(EnumSizeType),
                    size: z.nativeEnum(EnumSize),
                    style_type: z.nativeEnum(EnumStyleType),
                    style: z.nativeEnum(EnumStyle),
                    user_prompt: z.string(),
                    user_negative_prompt: z.string(),
                    width: z.number(),
                    updated_at: z.string().datetime(),
                    created_at: z.string().datetime(),
                    num_ratings: z.number().nullable(),
                    rating: z.number().nullable(),
                    num_downloads: z.number().nullable(),
                    num_shares: z.number().nullable(),
                    user_rating: z
                      .object({
                        rating: z.number().min(1).max(5).openapi({
                          description: 'The calculated rating from the community, from `1` to `5`, for this image.',
                        }),
                        max_rating: z.number().min(5).max(5).openapi({
                          description: 'The maximum rating that was possible for this `rating` when it was submitted.',
                        }),
                        updated_at: z.string().datetime().openapi({
                          description: 'The date and time at which the image rating was last updated.',
                        }),
                        created_at: z.string().datetime().openapi({
                          description: 'The date and time at which the image rating was created.',
                        }),
                      })
                      .nullable(),
                  }),
                )
                .optional(),
              updated_at: z.string().datetime(),
              created_at: z.string().datetime(),
            }),
          })
          .openapi({
            title: 'Get Image Generation Status',
            description: 'Get an image generation status',
          }),
        400: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        404: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
