import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'
import { EnumImageGenerationStatus } from '../../../../../lib/database/lib/generated/enums'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    generateImage: {
      metadata: {
        openApiTags: ['Actions'],
      },
      summary: '/ generate-image',
      description: 'Generates an image.',
      method: 'POST',
      path: '/generate-image',
      body: z.object({
        userPrompt: z.string(),
        userNegativePrompt: z.string().nullable().optional(),
        styleType: z.string(),
        style: z.string(),
        size: z.string().nullable().optional(),
        seed: z.string().nullable().optional(),
        imageGuide: z.string().nullable().optional(),
        imageGuideExtension: z.string().nullable().optional(),
        imageGuideType: z.string().nullable().optional(),
        imageGuideStrengthPercent: z.number().min(0).max(100).nullable().optional(),
        isGeneratedThroughApi: z.boolean().nullable().optional(),
        webhookUrl: z.string().nullable().optional(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.object({
              id: z.string().uuid(),
              status: z.nativeEnum(EnumImageGenerationStatus),
              gpuProviderId: z.string(),
            }),
          })
          .openapi({
            title: 'Generate Image',
            description: 'Generates an image',
          }),
        400: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        403: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
