import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    createStripeSubscription: {
      metadata: {
        openApiTags: ['Actions'],
      },
      summary: '/ create-stripe-subscription',
      description: 'Create a Stripe subscription for the currently-authenticated user.',
      method: 'POST',
      path: '/create-stripe-subscription',
      headers: z.object({
        authorization: z.string(),
      }),
      body: z.object({
        priceId: z.string(),
        couponCode: z.string().nullable().optional(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.object({
              type: z.enum(['setup', 'payment']),
              subscriptionId: z.string(),
              clientSecret: z.string().nullable(),
            }),
          })
          .openapi({
            title: 'Create Stripe Subscription',
            description: 'Create a Stripe subscription for the currently-authenticated user.',
          }),
        400: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        404: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
