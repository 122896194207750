import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'
import { EnumSize, EnumStyle, EnumStyleType } from '../../../../../lib/database/lib/generated/enums'
import { RUNPOD_STATUS_MAP } from '../../../../../lib/constants/lib/runpod'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    runpodWebhook: {
      metadata: {
        openApiTags: ['Actions'],
      },
      summary: '/ runpod-webhook',
      description: 'Update the current status of the images being generated.',
      method: 'POST',
      path: '/runpod-webhook',
      body: z.object({
        delayTime: z.number().optional(),
        executionTime: z.number().optional(),
        id: z.string(),
        status: z.nativeEnum(RUNPOD_STATUS_MAP),
        output: z
          .object({
            data: z.array(
              z.object({
                s3_key: z.string(),
                nsfw_detected: z.boolean(),
                nsfw_text_detected: z.boolean(),
                caption: z.string(),
                detailed_caption: z.string(),
                more_detailed_caption: z.string(),
                is_image_guided: z.boolean(),
                object_detection: z.string(),
                prompt_grounded_object_detection: z.string(),
                style_type_prompt: z.string(),
                style_type_negative_prompt: z.string(),
                style_prompt: z.string(),
                style_negative_prompt: z.string(),
                user_prompt: z.string(),
                user_negative_prompt: z.string(),
                width: z.number(),
                height: z.number(),
                steps: z.number(),
                guidance: z.number(),
                num_iterations: z.number(),
                batch_size: z.number(),
                seed: z.number(),
                style_type: z.nativeEnum(EnumStyleType),
                style: z.nativeEnum(EnumStyle),
                size: z.nativeEnum(EnumSize),
              }),
            ),
          })
          .optional(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.object({
              queueMessageId: z.string().nullable(),
              queueDeduplicationId: z.string(),
              queueGroupId: z.string(),
              queueSequenceNumber: z.string().nullable(),
            }),
          })
          .openapi({
            title: 'Stripe Webhook',
            description: 'Handles webhooks coming from Stripe',
          }),
        400: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
