import { EnumImageGenerationStatus, type EnumSize, type EnumStyle, type EnumStyleType } from '../../database/lib/generated/enums'

export interface RunpodOutputImage {
  s3_key: string
  nsfw_detected: boolean
  nsfw_text_detected: boolean
  caption: string
  detailed_caption: string
  more_detailed_caption: string
  object_detection: string
  is_image_guided: boolean
  prompt_grounded_object_detection: string
  style_type_prompt: string
  style_type_negative_prompt: string
  style_prompt: string
  style_negative_prompt: string
  user_prompt: string
  user_negative_prompt: string
  width: number
  height: number
  steps: number
  guidance: number
  num_iterations: number
  batch_size: number
  seed: number
  style_type: keyof typeof EnumStyleType
  style: keyof typeof EnumStyle
  size: keyof typeof EnumSize
}

export interface RunpodOutput {
  data: RunpodOutputImage[]
}

export const RUNPOD_STATUS_MAP = {
  IN_QUEUE: EnumImageGenerationStatus.IN_QUEUE,
  IN_PROGRESS: EnumImageGenerationStatus.IN_PROGRESS,
  COMPLETED: EnumImageGenerationStatus.COMPLETED,
  CANCELLED: EnumImageGenerationStatus.CANCELLED,
  FAILED: EnumImageGenerationStatus.FAILED,
  TIMED_OUT: EnumImageGenerationStatus.TIMED_OUT,
} as const
