import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    contactUs: {
      metadata: {
        openApiTags: ['Actions'],
      },
      summary: '/ contact-us',
      description: 'Sends an email to the PicTrix.ai team.',
      method: 'POST',
      path: '/contact-us',
      body: z.object({
        firstName: z.string(),
        lastName: z.string(),
        email: z.string(),
        topic: z.string(),
        message: z.string(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
          })
          .openapi({
            title: 'Contact Us',
            description: 'Sends an email to the PicTrix.ai team',
          }),
        400: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
