import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'
import { EnumImageType } from '../../../../../lib/database/lib/generated/enums'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    downloadImage: {
      metadata: {
        openApiTags: ['Getters'],
      },
      summary: '/ download-image',
      description: 'Downloads the image.',
      method: 'GET',
      path: '/download-image/:imageId',
      query: z.object({
        imageType: z.nativeEnum(EnumImageType),
      }),
      pathParams: z.object({
        imageId: z.string().uuid(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
          })
          .openapi({
            title: 'Download Image',
            description: 'Get the image contents and filename of a specific image',
          }),
        400: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        404: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
